<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow" :header="false">
      <template v-slot:header></template>
      <template v-slot:title>
        <v-row class="ma-1">
          <v-btn-toggle v-model="objectType" mandatory borderless dense @change="onChangeObjectType" class="ml-1">
            <v-btn value="objects" class="mx-1">
              <span class="hidden-md-and-down">Объекты</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> far fa-building </v-icon>
            </v-btn>
            <v-btn value="lampObjects" class="mr-1">
              <span class="hidden-md-and-down">Фонарики</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp">fas fa-lightbulb</v-icon>
            </v-btn>
            <v-btn value="agentObjects">
              <span class="hidden-md-and-down">Агенты</span>
              <v-icon :right="$vuetify.breakpoint.mdAndUp"> fas fa-user-secret </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      </template>
      <a-table-f-api
        ref="table"
        :api="types[objectType].url"
        :model="model"
        :useQuery="!true"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @click1="onClickRow($event)"
      >
        <template v-slot:item.actions="{ row }">
          <div class="d-flex">
            <v-btn
              elevation="0"
              :loading="!!(pdfLoading[objectType + '_' + row.id] && pdfLoading[objectType + '_' + row.id] == 1)"
              :disabled="!!pdfLoading[objectType + '_' + row.id]"
              x-small
              fab
              class="ma-0 ml-1"
              color="green"
              title="скачать"
              @click.stop="getPdf(row, 0)"
            >
              <v-icon>fas fa-file-export</v-icon>
            </v-btn>
            <v-btn v-if="false" elevation="0" x-small fab class="ma-0 ml-1" color="green" title="Просмотр" @click.stop="openLink(row, 1)">
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
          </div>
        </template>
      </a-table-f-api>
    </static-fullscreen-card>
    <s-edit-form-async ref="editField" />
    <ViewItem v-if="idItemShow" :idShow="idItemShow" :type="objectType" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  components: {
    ViewItem: () => import("./views/salesView"),
  },
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      objectType: "objects",
      showEditDialog: false,
      title: "",
      pdfLoading: {},
      types: { objects: { url: "/mechti/objects/broker" }, lampObjects: { url: "/mechti/lamp_objects" }, agentObjects: { url: "/mechti/agent_objects" } },
      apiPrint: { objects: { url: "/mechti/objects/published" }, lampObjects: { url: "/mechti/lamp_objects" }, agentObjects: { url: "/mechti/agent_objects" } },
      m: this.$store.getters["config/get"].models.sales,
      url: "/mechti/objects/broker",
    };
  },
  computed: {
    defaults() {
      let res = {
        sort: { key: "id", order: "DESC" },
        //   filters: { status_fin: this.objectType == "objects11" ? 1 : undefined },
        paramName: "sales",
      };
      return res;
    },
    modelT() {
      console.log("get model T");
      return this.getModelList(this.m, "list", false);
    },
    model() {
      let res = this.modelT;
      let fields = this.m[this.objectType].split(",");
      let opt = [];
      if (this.objectType == "objects") opt = this.getOptions({ options: "status_object" });
      if (this.objectType == "lampObjects") opt = this.getOptions({ options: "config.statuses.lamp_object" }).filter((s) => s.type == "lampObjects");
      if (this.objectType == "agentObjects") opt = this.getOptions({ options: "config.statuses.lamp_object" }).filter((s) => s.type == "agentObjects");
      res.find((m) => m.name == "status").options = [...opt];
      // res.find((m) => m.name == "ap_price_buy").editable = !(this.objectType == "objects");
      return res.filter((f) => fields.includes(f.name));
    },
  },
  created() {
    this.$root.title = "Продажи";
  },
  methods: {
    async callForm(e) {
      return this.$refs.editField.show(e);
    },
    async getPdf(row) {
      let res = await this.callForm({
        title: "Параметры презентации",
        data: { isContact: 1, name: this.$root.profile.name, phone: this.$root.profile.phone },
        model: [
          { name: "isContact", title: "Показывать контакты", type: "switcher", validator: ["req"] },
          { name: "name", title: "Имя", type: "string", validator: ["req"] },
          { name: "phone", title: "Телефон", type: "string", validator: ["req"], mask: "+# (###) ###-##-##" },
        ],
        btn: { ok: "Скачать", cancel: "Закрыть" },
      });
      if (res) {
        console.log(res);
        this.openLink(row, 0, res);
      } else {
      }
      return false;
    },
    openLink(row, show = 0, d = {}) {
      const n = this.objectType + "_" + row.id;
      this.$set(this.pdfLoading, n, 1);
      const _arrayBufferToBase64 = (buffer) => {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      };
      let params = d?.isContact ? { name: d.name, phone: d.phone } : {};
      this.$axios({
        method: "get",
        url: `https://api.gk-mechti.ru/api${this.apiPrint[this.objectType].url}/${row.id}/print`,
        responseType: "blob",
        params: { params },
      })
        .then(async (response) => {
          if (show) {
            const blob = response.data;
            const arrayBuffer = await blob.arrayBuffer();
            const base64 = _arrayBufferToBase64(arrayBuffer);
            const tag = document.getElementById("pdf");
            tag.setAttribute("src", "data:application/pdf;base64," + base64);
          } else {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `презентация_${row?.city || ""}_${row.name}.pdf`;
            link.click();

            // Освобождаем память
            window.URL.revokeObjectURL(link.href);
          }
          this.pdfLoading[n] = 0;
        })
        .catch((error) => {
          this.pdfLoading[n] = 2;
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при формировании презентации ",
          });
          console.error("Ошибка при загрузке файла:", error);
        });
      //let url = `https://api.gk-mechti.ru/api/mechti/${this.objectType}/published/${row.id}/print`;
      //window.open(url, "_blank");
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
    },
    onClickRow(d) {
      this.$router.push({ name: "sales_view", params: { type: this.objectType, id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
    onChangeObjectType() {
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
  },
};
</script>
